import React, { useState } from "react"
import clsx from "clsx"

import Root from "../components/root"
import classes from "./faq.module.css"

import Logo from "../assets/web_resources/logo-white.png"
import OurGoal from "../assets/web_resources/our-goal.png"
import Fantasy from "../assets/web_resources/fantasy.png"

import { useMediaQuery } from "react-responsive"
import Dropdown from "../components/Dropdown"
import Layout from "../components/layout"

const FAQPage = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" })
  const [isDropdownOpen, setisDropdownOpen] = useState(false)

  return (
    <Layout>
      <Root className={classes.root}>
        {!isDropdownOpen && (
          <div className={classes.logo} onClick={e => setisDropdownOpen(true)}>
            <img src={Logo} alt="" />
          </div>
        )}

        {isDropdownOpen && <Dropdown white2={true} />}
        <div className={classes.heading}>
          Frequently {isMobile && <br />} Asked {isMobile && <br />} Questions
        </div>
        <div
          className={classes.container}
          onClick={e => setisDropdownOpen(false)}
        >
          <img className={classes.fantasy} src={Fantasy} alt="" />
          <div className={classes.text}>
            <div className={clsx(classes.para, classes.first)}>
              <p className={classes.bold}>What type of videos do you edit?</p>
              <p>
                Our editing services covers any type of videos. From weddings
                and documentary films, to corporate, commercials, real estate,
                vlogs, explainer videos, social media, YouTube, animations,
                travel, family videos.
              </p>
              <p>
                Still having doubts? Drop us an email at
                <span>contact@themadjon.com</span> or use the live chat.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                What is the turnaround time for my video?
              </p>
              <p> The standard turnaround time is 5-7 days.</p>
              <p>
                {" "}
                We also have an expedite 72 hours delivery option, for a small
                fee.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                How much footage or how many video files can I send?
              </p>
              <p>
                There's no limit! You can upload as many GB as you want, but the
                pricing will depend on how much footage we need to go through.
              </p>
              <p>
                {" "}
                Most of the times, some footage will not be used in the final
                cut. We only pick the best scenes, that go with the story. Of
                course, there are exceptions: if we are talking about a
                documentary style wedding or film, most of your footage will end
                up in the final edit.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>Can you edit 4K videos?</p>
              <p>
                Yes, we can! Drone shots, 120 frames per second, you name it, if
                it's 4K you want, we will deliver.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                Can you provide multiple exports from the same main video?
              </p>
              <p>
                Yes, we can export multiple videos from the same main video in
                different aspect ratios for different platforms.
              </p>
              <p>
                We can also make multiple videos from the same footage, for a
                small fee.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                What video editing software do you use?
              </p>
              <p>Adobe Premiere Pro & After Effects.</p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                Can you provide the source project files?
              </p>
              <p>
                Yes, we can supply the project files, if needed, but there are a
                lot of things we need to factor in, such as: plugins and
                templates used, LUTs, fonts and the compatibility between our
                machines and yours.
              </p>
              <p>
                It's not a simple process and we need to talk about the
                logistics, but it's possible if you want it.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>Can you do revisions?</p>
              <p>
                Yes, we do 3 rounds of free revisions. We will implement changes
                based on you feedback. We want you to have the best edit there
                is.
              </p>
              <p>
                We take pride in our work and can honestly say we have it right
                in just one round of revisions, but if you are not happy with
                your edit, please provide us extra guidelines until our edit
                reaches your standards.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                How can I be sure of the quality of your video editing services?
              </p>
              <p>
                We wanted to make the MAD JON an experience for you. From our
                site navigation, to our easy 3 steps questionnaire, we wanted to
                give you a high-end experience.
              </p>
              <p>
                If we didn't convince you, we can have a call and discuss your
                requirements. You can also check our portfolio to get an idea
                about the level of quality.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                Do you outsource the video editing to someone else?
              </p>
              <p>
                No, for now we have an office in Bucharest, Romania and our
                little team takes care of everything. We used to work for one of
                the biggest video editing services, but we had such bad
                experience with the management and the way they treated us, that
                we decided to form a community of editors, that respect each
                other and make no difference in race, gender, sexual orientation
                and age.
              </p>
              <p>
                Our dream is to grow this community and have MAD JONs all over
                the world, like a big family.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                How much does it cost to edit my video?
              </p>
              <p>
                You can use our <span>pricing tool</span> to get a personalized
                offer based on your needs. We know for a fact that we have the
                lowest prices on the internet.
              </p>
              <p>
                If you become a regular customer, we will make a special offer
                based on the number of videos you provide us monthly/weekly.
              </p>
              <p>
                We know that some projects might need to be quoted individually,
                so feel free to <span>contact us</span> and get your custom
                offer.
              </p>
              <p>
                Fancy a <span className={classes.yellow}>discount</span> ?
                Follow us on Instagram & YouTube.
                <span className={classes.yellow}>Extra discount</span>? Let us
                put our name on your video and it will add up!
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                How can I talk to customer support?
              </p>
              <p>
                You can email us at <span>contact@themadjon.com</span> , or you
                can use the live chat on the website.
              </p>
              <p>
                If you want a live call, please send us your contact details and
                a date & time when you will be available. One of us will call
                you back to discuss your project.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                What techniques do your video editing services include?
              </p>
              <p>
                Everything you can think of, we can do! We had clients from all
                over the world, from all fields, so we pushed our creative
                boundaries over the top. Here is a short list of the most commin
                video editing techniques that our clients asked from us: <br />
                <div className={classes.bullets}>
                  <div>- cuts & transitions</div>
                  <div>- color grading </div>
                  <div>- audio mixing</div> <div>- picture stabilization</div>
                  <div>- titles and text overlays</div>{" "}
                  <div>- motion graphics</div>
                  <div>
                    - applying effects on pictures so we can add motion to them
                  </div>
                </div>
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                My footage is not professional, can you work with it?
              </p>
              <p>
                Yes, of course we can! It's not about the quality of your
                footage, it's about the story behind it. We can honestly say
                that emotion is the most important for us.
              </p>
              <p>You don't need to be a videographer to work with us.</p>
              <img className={classes.ourGoal} src={OurGoal} alt="" />
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                Is there a limit on duration of the final video edit?
              </p>
              <p>
                There is no limit. Of course, it will depend on how much raw
                footage we have available and how much of it we can use. We are
                doing our best to meet your requirements. In our{" "}
                <span>questionnaire</span> , you can tell us how long you expect
                your final cut to be.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>Can you do voice overs?</p>
              <p>
                Yes, we work with voice over artists and we can produce
                professional voice overs in different accents and languages.
                This will increase the price of the project and may extend the
                turnaround time.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                Do you supply the music for the videos?
              </p>
              <p>
                Of course! We give you a bunch of free stuff, including music.
                We use music licensing platforms that provide music and sound
                effects, so you don't have any copyright issues.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                Can you use stock footage and photos?
              </p>
              <p>
                Yes, we have a library of stock footage & photos that we can use
                on your videos.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>How does the whole process work?</p>
              <p>Simple is the word to describe it.</p>
              <p>
                {" "}
                By using our 3 step <span>questionnaire</span> , you provide us
                with your guidelines. We automatically calculate your price
                based on your brief. For proof of commitment to your project,
                you are required to deposit 20% of its value.
              </p>
              <p>
                After this, you share your raw footage with us using Google
                Drive, Dropbox or any similar service. We edit the video
                according to your guidelines and get back to you with the 1st
                cut.
              </p>
              <p>
                Based on your feedback, if you have any issues with the 1st cut,
                we add/substract/modify your edit.
              </p>
              <p>
                When you are happy with your edit, you make the full payment and
                you receive the final cut without watermark.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                Can you match a video editing style?
              </p>
              <p>
                Yes, we can! We can also make sure your videos match your brand
                style, if you provide us with a branding package.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>Can I see examples of your work?</p>
              <p>
                Absolutely, please check out our portfolio. We value the privacy
                of our clients and we don't post all of our work. If you need
                more examples, please contact us.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>What kind of animations do you do?</p>
              <p>
                We provide text animations, calls to action and logo
                animations.We don't do complex 2D and 3D animations from
                scratch. We can't create your footage for the project from
                scratch. However, we work with templates to create custom simple
                animations.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                I can’t find a matching category for my type of video. What
                should I do?
              </p>
              <p>
                When going through our <span>questionnaire</span> , please pick
                the category named OTHER, then describe your project so we know
                what you wish to get out of your edit. If you are still unclear,
                please send us a message at <span> contact@themadjon.com</span>{" "}
                and we will get back at you shortly.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                Do I need to pay the full amount before I see the final result?
              </p>
              <p>
                No, you only pay 20% deposit to start your project. You will pay
                the rest of the payment when you are happy with your final edit.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                I have a selection of still photographs that I would like to use
                in the video montage, is this possible?
              </p>
              <p>
                Yes, we can turn them into a video. We have templates to suit
                any mood, from romantic to modern and fun. Simply choose your
                style and we will turn your pictures into a visual journey. We
                can also add text, voice, music, whatever you want.
              </p>
              <p>
                Want to include photos with your footage? We can also do that,
                please use our <span>questionnaire</span> and let us do wonders
                with your memories.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                What kind of devices can I use to record my videos?
              </p>
              <p>
                Any device is fine: tablet, smartphone, camcorder, digital
                camera, professional camera, drone, we edit ANY KIND of footage.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                Can I choose the music for my video?
              </p>
              <p>
                Of course you can! You can choose from a variety of genres,
                styles, or you can send us your choice of music.
              </p>
              <p>
                You can also let us pick for you! All our founding members have
                a thing for music, so be sure we will choose the best song that
                fits your uniquely tailored story.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                Can you remove the unwanted sounds and noises from the videos?
              </p>
              <p>
                We do sound editing, but correcting the sound is something else.
                You can give us a sample <span>here</span> and we will tell you
                if it's possible of not. Usually this is a completely different
                field.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                How do I calculate the length of time of my footage?
              </p>
              <p>
                Select all your videos and right click on them -> Open with ->
                your media player. This will show you the total length of all
                files in minutes.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                Will you share my videos on your site or any other social
                platforms?
              </p>
              <p>
                If you explicitly tell us not to use it, we will keep your data
                private and safe. Your privacy and security are important to us.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>Is my video data safe with you?</p>
              <p>
                Yes, your video data is safe with us because we are using
                specialized data transfer services, provided by third parties
                such as Dropbox and Google Drive. If you want to know more,
                please check
                <span>our privacy policy and terms & conditions</span> .
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                What payment methods do you accept?
              </p>
              <p>
                We accept PayPal and we use Stripe Inc. services as a solution
                for payment processing gateway service, so we accept all major
                credit/debit cards.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>What is raw footage?</p>
              <p>
                Raw footage is the unedited media files you give us that forms
                the basis for your project.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                How do I download my finished video?
              </p>
              <p>
                We will provide you a link from where you can download the
                finished video.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                Do you keep all client files and if so, for how long?
              </p>
              <p>
                After you receive your final cut without watermark, we will
                delete all files we hold within 15 days. We guarantee you
                confidentiality.
              </p>
            </div>

            <div className={classes.para}>
              <p className={classes.bold}>
                What if I want to have more than 3 revisions?
              </p>
              <p>
                3 revisions are usually more than enough to come up with a
                perfect final video. People usually don't require more
                revisions. If you do want more revisions then we are happy to
                provide these but we will charge an extra $20 per revision.
              </p>
            </div>
          </div>
        </div>
      </Root>
    </Layout>
  )
}

export default FAQPage
